import React from 'react';
import PropTypes from 'prop-types';
import {merge} from '../../../lib/object';
import {Trans, t} from '@lingui/macro';
import {Button, Form, Input, Radio} from 'antd';
import {createFetchDepartmentAdd, createFetchDepartmentEdit} from '../../../backend/apiCalls';
import {formItemLayout, tailFormItemLayout, mandatoryRule} from '../../../constants/form';
import {Department} from '../../../constants/propTypesDefinitions';
import SingleFileUpload from '../../general/SingleFileUpload';
import Select from '../../general/Select';
import ProductSelect from '../../project/ProductSelect';
import EmployeesSelect from '../../project/EmployeesSelect';
import { addQueryParamsToUrl, getDomain } from '../../../lib/url';
import { EXPORT_TARGET, SETUP_MAIL_TARGET } from '../../../constants/apiEndpoints';
import CategoriesTreeSelect from '../../project/CategoriesTreeSelect';
import PriceLevelSelect from '../../project/PriceLevelSelect';
import LanguageSelect from '../../project/LanguageSelect';
const FormItem = Form.Item;

/**
 * @dusan
 */

class DepartmentForm extends React.PureComponent {
    static propTypes = {
        onClose: PropTypes.func.isRequired,
        onSubmitFinish: PropTypes.func.isRequired,
        values: PropTypes.shape({
            department: Department,
            isEdit: PropTypes.bool.isRequired,
        }).isRequired,
    };

    constructor(props) {
        super(props);
        this.state = {
            loading: false,
        };
    }

    componentDidMount() {
        setTimeout(() => {
            if(this.first != null && this.first.focus != null)
                this.first.focus();
        }, 0)
    }

    handleSubmit = (e) => {
        const {onSubmitFinish, values} = this.props;
        e.preventDefault();
        this.props.form.validateFieldsAndScroll((err, formValues) => {
            if (!err) {
                this.setState({loading: true});
                const {isEdit, department ={}} = values;
                const fetchFunction = isEdit ? createFetchDepartmentEdit() : createFetchDepartmentAdd();
                const checkedValues = isEdit ?
                    merge(formValues, {id: department.id}) : formValues;
                fetchFunction(checkedValues).then((response) => {
                    this.setState({
                        loading: false,
                    });
                    onSubmitFinish(response);
                });
            }
        });
    };

    render() {
        const {loading} = this.state;
        const {values: {isEdit, department = {}}, onClose} = this.props;
        const {getFieldDecorator} = this.props.form;
        const setupMailUrl = addQueryParamsToUrl(getDomain() + SETUP_MAIL_TARGET, {username: department.e_mail});

        return <Form onSubmit={this.handleSubmit}>
            <FormItem
                {...formItemLayout}
                label={<Trans>Názov</Trans>}
            >
                {getFieldDecorator('name', {rules: [mandatoryRule], initialValue: department.name})(
                    <Input ref={node => this.first = node}/>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Logo</Trans>}
            >
                {getFieldDecorator('logo', {initialValue: department.logo})(
                    <SingleFileUpload
                        showPreview={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Je aktívne</Trans>}
            >
                {getFieldDecorator('is_active', {initialValue: department.is_active != null ? department.is_active : 1})(
                    <Radio.Group>
                        <Radio.Button value={1}><Trans>Áno</Trans></Radio.Button>
                        <Radio.Button value={0}><Trans>Nie</Trans></Radio.Button>
                    </Radio.Group>
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>URL detailu objednávky</Trans>}
            >
                {getFieldDecorator('order_details_url', {initialValue: department.order_details_url})(
                    <Input
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Predvolený obchodník</Trans>}
            >
                {getFieldDecorator('id_dealer', {initialValue: department.id_dealer})(
                    <EmployeesSelect
                        allowClear={true}
                    />
                )}
            </FormItem>
            <div className="full-size-width text-right">
                <a href={setupMailUrl} target="_blank">
                    <Trans>konfigurovať e-mailový server</Trans>
                </a>
            </div>
            <FormItem
                {...formItemLayout}
                label={<Trans>E-mail</Trans>}
            >
                {getFieldDecorator('e_mail', {initialValue: department.e_mail})(
                    <Input
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Banka</Trans>}
            >
                {getFieldDecorator('bank', {initialValue: department.bank})(
                    <Input
                        allowClear={true}
                        placeholder='podľa nastavení firmy'
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>IBAN</Trans>}
            >
                {getFieldDecorator('iban', {initialValue: department.iban})(
                    <Input
                        allowClear={true}
                        placeholder='podľa nastavení firmy'
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>SWIFT</Trans>}
            >
                {getFieldDecorator('swift', {initialValue: department.swift})(
                    <Input
                        allowClear={true}
                        placeholder='podľa nastavení firmy'
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>IČ DPH</Trans>}
            >
                {getFieldDecorator('ic_dph', {initialValue: department.ic_dph})(
                    <Input
                        allowClear={true}
                        placeholder='podľa nastavení firmy'
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Predvolený jazyk</Trans>}
            >
                {getFieldDecorator('id_language', {initialValue: department.id_language})(
                    <LanguageSelect
                        allowClear={true}
                        placeholder='podľa nastavení firmy'
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Doména</Trans>}
            >
                {getFieldDecorator('domain', {initialValue: department.domain})(
                    <Input
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Shoptet eshop ID</Trans>}
            >
                {getFieldDecorator('shoptet_eshop_id', {initialValue: department.shoptet_eshop_id})(
                    <Input
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Shoptet token</Trans>}
            >
                {getFieldDecorator('shoptet_token', {initialValue: department.shoptet_token})(
                    <Input 
                        type="password" 
                        autoComplete="new-password"
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Kmeňová kategória</Trans>}
            >
                {getFieldDecorator('id_root_category', {initialValue: department.id_root_category})(
                    <CategoriesTreeSelect
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Cen. hladina predaj</Trans>}
            >
                {getFieldDecorator('id_price_level', {initialValue: department.id_price_level})(
                    <PriceLevelSelect
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem
                {...formItemLayout}
                label={<Trans>Cen. hladina nákup</Trans>}
            >
                {getFieldDecorator('id_purchase_price_level', {initialValue: department.id_purchase_price_level})(
                    <PriceLevelSelect
                        allowClear={true}
                    />
                )}
            </FormItem>
            <FormItem {...tailFormItemLayout}>
                <Button key="back" onClick={onClose}><Trans>Zrušiť</Trans></Button>
                <Button htmlType="submit" key="submit" type="primary" loading={loading} onClick={this.handleSubmit}>
                    {isEdit ? <Trans>Upraviť</Trans> : <Trans>Vytvoriť</Trans>}
                </Button>
            </FormItem>
        </Form>
    }
}

export default Form.create()(DepartmentForm);