import React from 'react';
import PropTypes from 'prop-types';
import List from '../../general/List';
import withDataHOC from '../../dataProvider/withDataHOC';
import {GLOBAL_DATA} from '../../../constants/globalData';
import DepartmentEdit from './DepartmentEdit';
import DepartmentRemove from './DepartmentRemove';
import { createFetchDepartmentAll } from '../../../backend/apiCalls';
import VisibilityIcon from '../../general/VisibilityIcon';

/**
 * @fero
 */

class DepartmentsList extends React.PureComponent {
    constructor(props) {
        super(props);
        this.state = {
            selectedDepartmentId: null,
            departments: [],
            loading: false
        }
    }

    static propTypes = {
        [GLOBAL_DATA.FETCH_HANDLER]: PropTypes.func.isRequired,
    };

    componentDidMount() {
        this.fetchDepartments();
    }

    fetchDepartments = () => {
        const fetchHandler = this.props[GLOBAL_DATA.FETCH_HANDLER];
        this.setState({loading: true});
        fetchHandler(
            createFetchDepartmentAll(),
            {show_all: 1},
            (result) => {
                this.setState({
                    departments: result,
                    loading: false,
                });
            }
        )

        const reloadData = this.props[GLOBAL_DATA.RELOAD_DATA];
        reloadData([GLOBAL_DATA.DEPARTMENTS]);
    }

    onSelect = (selectedId) => {
        this.setState({
            selectedDepartmentId: selectedId,
        })
    };

    render() {
        const {selectedDepartmentId, departments, loading} = this.state;
        return loading ? null : <List
                values={departments}
                onItemClick={(value) => {
                    this.onSelect(value.id);
                }}
                selectedValueId={selectedDepartmentId}
            >
                {departments.map((department) => {
                    return <div key={department.id} className="d-flex flex-wrap align-items-center">
                        <VisibilityIcon isVisible={department.is_active}/>
                        {department.name}
                        {department.id == selectedDepartmentId ? [
                            <DepartmentEdit
                                key="edit"
                                department={department}
                                reload={this.fetchDepartments}
                            />,
                            <DepartmentRemove
                                key="remove"
                                department={department}
                                reload={this.fetchDepartments}
                            />
                        ] : null}
                    </div>
                })}
        </List>;
    }

}

export default withDataHOC([GLOBAL_DATA.FETCH_HANDLER, GLOBAL_DATA.RELOAD_DATA])(DepartmentsList);